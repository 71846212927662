import React from 'react';
import './Button.css'
import Icon from '../../assets/icons';
import { Link } from 'react-router-dom';
import { LoadingContainer } from '../overlay/desktop/Loading';

const Button = ({
    to = "/",
    value = "Button",
    icon = "",
    type = "text",
    background = true,
    height = "",
    action = false
}) => {
    let content = false;
    switch (type) {
        case "text":
            content = <span>{value}</span>;
            break;
        case "icon-left":
            content = (
                <>
                    <Icon name={icon} fill="#4A4A4A" height={height ? height : "16px"} />
                    <span className="button-separator-thin" />
                    <span className="button-content-text">{value}</span>
                </>
            );
            break;
        case "icon-right":
            content = (
                <>
                    <span>{value}</span>
                    <span className="button-separator-thin" />
                    <Icon name={icon} fill="#4A4A4A" height={height ? height : "20px"} />
                </>
            );
            break;
        case "icon":
            content = <Icon name={value} />;
            break;
        default:
            content = (<div>no button type found</div>);
            break;
    }

    return content ? (action
        ? (<div onClick={() => action()} className={background ? "button-base" : "button-transparent"}>{content}</div>)
        : (<Link className={background ? "button-base" : "button-transparent"} to={to}>{content}</Link >)
    ) : <LoadingContainer />
}

export default Button;