import React from 'react';
import './Container.css'

const Container = (props) => {
    const {size,children,columns = "1"} = props
    switch (size) {
        case "large":
            return <div className={"container-large-" + columns}>{children}</div>
        default:
            return <div className={"container-fit-" + columns}>{children}</div>;
    }
}

export default Container;