import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "10px",
  height = "17px",
  className = "",
  viewBox = "0 0 10 17"
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        transform="translate(-592.000000, -392.000000)"
        d="M600.168945,408.450195 C600.757812,408.450195 601.214844,407.993164 601.214844,407.413086 C601.214844,407.123047 601.100586,406.859375 600.898438,406.657227 L594.746094,400.645508 L600.898438,394.651367 C601.100586,394.449219 601.214844,394.176758 601.214844,393.895508 C601.214844,393.31543 600.757812,392.858398 600.168945,392.858398 C599.887695,392.858398 599.641602,392.963867 599.448242,393.157227 L592.610352,399.836914 C592.364258,400.06543 592.241211,400.34668 592.241211,400.654297 C592.241211,400.961914 592.364258,401.225586 592.601562,401.462891 L599.439453,408.151367 C599.641602,408.344727 599.887695,408.450195 600.168945,408.450195 Z"
      />
    </svg>
  );

export default SVG;
