import React from 'react';

const Divider = (props) => {
    const {
        color = false,
        height = "0px",
        margin = "none"
    } = props;
    const styles = {
        border: "none",
        margin: margin + " 0px",
        width: "100%",
        height: height,
        backgroundColor: color ? color : "none",
        display: 'inline-block',
    };
    return <hr style={styles}/>;
}
 
export default Divider;