import React from 'react';
import './Paper.css'

const Paper = (props) => {
    const { size = "large", children, padding, align } = props
    return (
        <div className={"card-" + size + " card-padding-" + padding + " card-align-" + align}>
            {children}
        </div>
    );
}

export default Paper;