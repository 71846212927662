import React from 'react';
import './Card.css'
import { Link } from 'react-router-dom';
import Paper from '../paper/Paper';
import moment, { now } from 'moment';

const Card = (props) => {
    const {
        type = "classic",
        size = "large",
        to = false,
        title = "",
        description = "",
        firstName = "firstName",
        lastName = "lastName",
        creationDate = Date.now(),
        commentsNumber = "3",
        likesNumber="4"
    } = props;

    let content;
    switch (type) {
        case "idea":
            content = (
                <Paper size={size}>
                    <div className="flexgrid">
                        <div className="left">
                            <p className="board-title">{title}</p>
                            <p className="board-content">{description}</p>
                        </div>
                        <div className="right">
                            <p className="board-meta">{firstName + ' ' + lastName[0].toUpperCase() + '.'}</p>
                            <p className="board-meta">{creationDate ? moment(creationDate).fromNow() : ''}</p>
                            <span className="board-meta">
                                <p>{commentsNumber} c</p>
                                <img src={''} alt="comments" />
                            </span>
                        </div>
                    </div>
                </Paper>
            );
            break;
        default:
            content = (
                <Paper size={size}>
                    <p className="card-title">{title}</p>
                    <p className="card-description">{description}</p>
                </Paper>
            );
            break;
    }

    return to ? (
        <Link to={to}>
            {content}
        </Link>
    ) : (<>{content}</>);
}

export default Card;