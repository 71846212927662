import React from 'react';
import './Avatar.css';
import t from 'prop-types';

const Avatar = (props) => {
    const {
        type,
        value,
        onHover,
        onQuitHover,
        avatarFront
    } = props
    switch (type) {
        case "letter":
            return (
                <div className={"avatar" + (avatarFront ? " avatar-front" : "")} onMouseEnter={onHover} onMouseLeave={onQuitHover}>
                    <span>{value}</span>
                </div>
            );
        default:
            return (<></>);
    }
}

Avatar.propTypes = {
    /**
     * Type of the Avatar. For now, only letter is supported
     */
    type: t.oneOf(["letter"]),
    /**
     * Initials displayed in the Avatar (two letters)
     */
    value: t.string.isRequired,
    /**
     * When the mouse enter on the component, this function is triggered
     */
    onHover: t.func,
    /**
     * When the mouse quit the component, this function is triggered
     */
    onQuitHover: t.func,
    /**
     * Position of the avatar: on the front if true
     */
    avatarFront: t.bool
}

Avatar.defaultProps = {
    type: "letter",
    value: "AB",
    onHover: false,
    onQuitHover: false,
    avatarFront: false
}

export default Avatar;