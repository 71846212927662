import React, { useContext } from 'react';
import './DropDown.css'
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import l from '../../assets/translation/locales'

const DropDown = (props) => {
    const { open, values, onHover, onQuitHover } = props
    const { user } = useContext(UserContext);

    return open ? (
        <div className="dropdown-content" onMouseEnter={onHover} onMouseLeave={onQuitHover}>
            <Link className="dropdown-header" key="profile" to="/profile">{`${user.data.firstName} ${user.data.lastName}`}</Link>
            {values.map(item =>
                <div className="dropdown-content-item" key={item.to}>
                    <Link to={item.to} onClick={item.action}>{l(item.value)}</Link>
                </div>)}
        </div>
    ) : <div></div>;
}

export default DropDown;