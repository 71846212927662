import React from 'react';

const Select = (props) => {
    const {
        name = "",
        values = [],
        defaultValue = "",
        onChange = false,
        idKey = "",
        valueKey = ""
    } = props;
    return (
        <select name={name} id={name} defaultValue={defaultValue} onChange={(e) => onChange(e.target.value)}>
            {values.map((h) => <option value={h.id} key={h[idKey]} >{h[valueKey]}</option>)}
        </select>
    );
}

export default Select;