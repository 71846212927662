const locales = {
    "fr": {
        "connect": "Connexion",
        "create-box": "Créer une boîte à idées",
        "signout":"Déconnexion",
        "loading":"Chargement",
        "home":"Accueil",
        "welcome":"Bienvenue",
        "discover-suggestion-boxes-of-the-moment":"Découvrez les boîtes à idées du moment",
        "to-connect":"Connectez-vous à votre compte",
        "no-account-yet":"Pas encore de compte ?",
        "email":"Email",
        "password":"Mot de passe",
        "to-singup":"Créez votre compte utilisateur",
        "first-name":"Prénom",
        "last-name":"Nom",
        "already-account":"Vous avez déjà un compte ?",
        "every-state-must-be-filled":"Tous les champs doivent être remplis",
        "to-create-box":"Créer une nouvelle boîte à idées",
        "name":"Nom",
        "description":"Description",
        "create":"Créer",
        "new-box":"Nouvelle boîte à idées",
        "all":"Tout",
        "all-description":"Toutes les idées",
        "there-are":"Il y a",
        "categories":"catégories"
    }
}

let langage = 'fr'; 

export const setLangage = (l) => {langage = l};

const l = (value) => {
    return locales[langage][value] ? locales[langage][value] : value;
};

export default l;