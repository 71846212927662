import React from 'react';
import './Overlay.css'
import logoLarge from './../../../assets/icons/logo-large.svg'

const Loading = () => {
    return (
        <div className="overlay">
            <div className="overlay__inner">
                <img className="overlay__logo" src={logoLarge} alt="Brusketa"/>
                <div className="overlay__content"><span className="spinner"></span></div>
            </div>
        </div>
    );
}

export default Loading;

const LoadingContainer = () => {
    return (
            <div className="container overlay__container">
                <div className="overlay__content"><span className="spinner"></span></div>
            </div>

    );
}
 
export {Loading, LoadingContainer};