import React from "react";

import ChevronLeft from "./ChevronLeft";
import PersonCircle from "./PersonCircle";
import LogoLarge from "./LogoLarge";

const Icon = (props) => {
    switch (props.name) {
        case "chevron-left":
            return <ChevronLeft {...props} />;
        case "person-circle":
            return <PersonCircle {...props} />;
        case "logo-large":
            return <LogoLarge {...props} />;
        default:
            return;
    }
};

export default Icon;
