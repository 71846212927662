import React from 'react';
import './Form.css'

const Form = (props) => {
    const {
        children,
        onSubmit
    } = props;
    return (
        <form onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : 'test'} className="form">
            {children}
        </form>
    );
}
 
export default Form;