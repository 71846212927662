// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-avatar-avatar-mdx": () => import("./../../src/components/avatar/Avatar.mdx" /* webpackChunkName: "component---src-components-avatar-avatar-mdx" */),
  "component---src-components-breadcrumbs-breadcrumbs-mdx": () => import("./../../src/components/breadcrumbs/Breadcrumbs.mdx" /* webpackChunkName: "component---src-components-breadcrumbs-breadcrumbs-mdx" */),
  "component---src-components-button-button-mdx": () => import("./../../src/components/button/Button.mdx" /* webpackChunkName: "component---src-components-button-button-mdx" */),
  "component---src-components-card-card-mdx": () => import("./../../src/components/card/Card.mdx" /* webpackChunkName: "component---src-components-card-card-mdx" */),
  "component---src-components-container-container-mdx": () => import("./../../src/components/container/Container.mdx" /* webpackChunkName: "component---src-components-container-container-mdx" */),
  "component---src-components-divider-divider-mdx": () => import("./../../src/components/divider/Divider.mdx" /* webpackChunkName: "component---src-components-divider-divider-mdx" */),
  "component---src-components-dropdown-dropdown-mdx": () => import("./../../src/components/dropdown/Dropdown.mdx" /* webpackChunkName: "component---src-components-dropdown-dropdown-mdx" */),
  "component---src-components-form-form-mdx": () => import("./../../src/components/form/Form.mdx" /* webpackChunkName: "component---src-components-form-form-mdx" */),
  "component---src-components-link-link-mdx": () => import("./../../src/components/link/Link.mdx" /* webpackChunkName: "component---src-components-link-link-mdx" */),
  "component---src-components-list-list-mdx": () => import("./../../src/components/list/List.mdx" /* webpackChunkName: "component---src-components-list-list-mdx" */),
  "component---src-components-navbar-desktop-navbar-mdx": () => import("./../../src/components/navbar/desktop/Navbar.mdx" /* webpackChunkName: "component---src-components-navbar-desktop-navbar-mdx" */),
  "component---src-components-overlay-desktop-loading-mdx": () => import("./../../src/components/overlay/desktop/Loading.mdx" /* webpackChunkName: "component---src-components-overlay-desktop-loading-mdx" */),
  "component---src-components-paper-paper-mdx": () => import("./../../src/components/paper/Paper.mdx" /* webpackChunkName: "component---src-components-paper-paper-mdx" */),
  "component---src-components-select-select-mdx": () => import("./../../src/components/select/Select.mdx" /* webpackChunkName: "component---src-components-select-select-mdx" */),
  "component---src-components-text-field-text-field-mdx": () => import("./../../src/components/textField/TextField.mdx" /* webpackChunkName: "component---src-components-text-field-text-field-mdx" */),
  "component---src-introduction-mdx": () => import("./../../src/Introduction.mdx" /* webpackChunkName: "component---src-introduction-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

