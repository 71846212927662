import React, { createContext, useState } from 'react';
import l from '../assets/translation/locales';

export const NavigationContext = createContext();

const NavigationContextProvider = (props) => {
    const [nav, setNav] = useState({
        page: l("loading"),
        left: ("home"),
        previous: false,
        text: false
    });
    const [path, setPath] = useState([{ name: "home", uri: "/" }]);

    const setNavigation = (page, left, previous, text) => {
        setNav({ page, left, previous, text });
    }

    return (
        <NavigationContext.Provider value={{ nav, path, setNavigation, setPath }}>
            {props.children}
        </NavigationContext.Provider>
    );
}

export default NavigationContextProvider;