import React from 'react';
import './TextField.css';

const TextField = (props) => {
    const { label, value, setValue, type } = props;

    return (
        <label className="pure-material-textfield-outlined">
            <input placeholder={label} onChange={(e) => setValue(e.target.value)} value={value} type={type} />
            <span>{label}</span>
        </label>
    );
}

export default TextField;