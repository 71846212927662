import React, { useContext, useState } from 'react'
import './Navbar.css';
import { UserContext } from '../../../contexts/UserContext'
import { NavigationContext } from '../../../contexts/NavigationContext';
import Button from '../../button/Button';
import Avatar from '../../avatar/Avatar';
import DropDown from '../../dropdown/Dropdown';

const Navbar = () => {
    const { user, signOut } = useContext(UserContext);
    const { nav } = useContext(NavigationContext);
    const [hover, setHover] = useState(false)

    let linkLeft = '/';
    switch (nav.left) {
        case 'home':
            linkLeft = '/'
            break;
        case 'back':
            linkLeft = nav.previous
            break;
        case 'organization':
            linkLeft = nav.previous
            break;
        default:
            linkLeft = '/'
            break;
    }

    const handleHover = () => {
        setHover(true);
    };

    const handleHoverQuit = () => {
        setHover(false);
    };

    return (
        <div className="navbar noselect">
            <div className="left">
                <div className="link-left">
                    {nav.left === 'home' ?
                        <Button type="icon" background={false} value="logo-large" to="/" /> :
                        <Button type="icon-left" icon="chevron-left" background={false} value={nav.text ? nav.text : "Back"} to={linkLeft} />}
                </div>
            </div>
            <div className="center"><h1>{nav.page}</h1></div>
            <div className="right">
                <div className="link-right">
                    {user.isConnected && user.data.lastName && user.data.firstName ?
                        <div>
                            <Avatar avatarFront={true} type="letter" value={user.data.firstName[0].toUpperCase() + user.data.lastName[0].toUpperCase()} onHover={handleHover} onQuitHover={handleHoverQuit} />
                            <DropDown open={hover} onHover={handleHover} onQuitHover={handleHoverQuit} values={[
                                { value: "create-box", to: "/create-box", icon: "" },
                                { value: "signout", to: "/", icon: "", action: () => signOut() }
                            ]} />
                        </div> :
                        <Button value="Connexion" type="icon-right" icon="person-circle" to='/signin' />
                    }
                </div>
            </div>
        </div>
    );
}

export default Navbar