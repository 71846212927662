import React, { createContext, useState, useEffect } from 'react';
import firebase from 'firebase/app'

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const users = firebase.firestore().collection('users');

    const [user, setUser] = useState({
        updated: false,
        isConnected: false,
        status: false,
        data: {
            langage: 'fr'
        }
    });

    const signInUser = (email, password) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(setUser({ ...user })).catch(function (error) {
            setUser({ ...user, status: error.message, data: { langage: 'fr' } });
        });
    }

    const signUpUser = (email, password, firstName, lastName) => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then((u) => {
            console.log("créé dans auth", u);
            users.doc(u.user.uid).set({
                firstName: firstName,
                lastName: lastName,
                email: email,
                creationDate: firebase.firestore.Timestamp.now(),
                langage: window.navigator.language.slice(0, 2)
            }).then(() => {
                console.log("créé dans firestore")
                getUserData();
                // users.doc(u.user.uid).get().then(function (doc) { console.log("Document data:", doc.data())});
                // users.doc(firebase.auth().currentUser.uid).get(e => console.log("ici, c'est censé marcher",e.data()));
                // setUser({...user,isConnected:true});
            }).catch((error) => {
                setUser({ ...user, data: { langage: 'fr' }, status: error.message });
            })
        }).catch((error) => {
            setUser({ ...user, data: { langage: 'fr' }, status: error.message });
        });
    }

    const signOut = () => {
        firebase.auth().signOut().then(() => console.log("User signed out")).catch((error) => console.log("Erreur sign out", error))
    }

    const getUserData = () => {
        users.doc(firebase.auth().currentUser.uid).onSnapshot(e => {
            setUser({
                updated: true,
                isConnected: true,
                data: e.data()
            });
        }, err => {
            console.log(`Error while updating user: ${err}`);
        });
    }

    const onAuthStateChange = () => {
        const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
            console.log("Mise à jour : ", user);
            if (currentUser) {
                getUserData();
            } else {
                setUser({ ...user, updated: true, isConnected: false, data: { langage: 'fr' } });
            }
        })
        return () => unsubscribe();
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChange();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <UserContext.Provider value={{ user, setUser, signInUser, signUpUser, signOut, onAuthStateChange }}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;