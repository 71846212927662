import React from "react";

const SVG = ({
    style = {},
    fill = "#000",
    height = "50px",
    className = "",
    viewBox = "0 0 50 50"
}) => (
        <svg
            width={height}
            style={style}
            height={height}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                fill={fill}
                d="M24.4296875,49.3359375 C37.71875,49.3359375 48.6875,38.3671875 48.6875,25.1015625 C48.6875,11.8125 37.6953125,0.84375 24.40625,0.84375 C11.140625,0.84375 0.1953125,11.8125 0.1953125,25.1015625 C0.1953125,38.3671875 11.1640625,49.3359375 24.4296875,49.3359375 Z M38.6796875,38.296875 C36.1953125,35.5546875 31.0859375,33.1875 24.4296875,33.1875 C17.796875,33.1875 12.6875,35.5546875 10.203125,38.2734375 C7.015625,34.828125 5.0703125,30.2109375 5.0703125,25.1015625 C5.0703125,14.34375 13.671875,5.6953125 24.40625,5.6953125 C35.1640625,5.6953125 43.8125,14.34375 43.8359375,25.1015625 C43.8359375,30.2109375 41.890625,34.8515625 38.6796875,38.296875 Z M24.4296875,29.34375 C28.9765625,29.390625 32.515625,25.5 32.515625,20.484375 C32.515625,15.75 28.953125,11.7890625 24.4296875,11.7890625 C19.9296875,11.7890625 16.34375,15.75 16.3670733,20.484375 C16.390625,25.5 19.90625,29.3203125 24.4296875,29.34375 Z"
            />
        </svg>
    );

export default SVG;
